<template>
  <div class="vue-cal-scheduler">
    <CardLoader v-if="isLoading" :with-text="false"/>
    <vue-cal
        active-view="month"
        :disable-views="['week', 'day']"
        :locale="language"
        :events="events"
        :selected-date="selectedDate"
        :time="false"
        events-count-on-year-view
        events-count-on-years-view
        :events-on-month-view="true"
        :on-event-click="onEventClick"
        @view-change="onViewChange"
        @cell-dblclick="onDateClick"
    />
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/ru.js';
import 'vue-cal/dist/i18n/ka.js';
import 'vue-cal/dist/vuecal.css';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    VueCal,
    CardLoader
  },
  props: {
    events: {
      default: []
    },
    selectedDate: {
      default: null
    }
  },
  data: () => ({
    dateRange: null,
    isLoading: false
  }),
  beforeMount() {
    this.getData();
  },
  computed: {
    language() {
      let lang = localStorage.getItem('language');
      if (lang === 'ge') lang = 'ka';
      return lang || 'en';
    }
  },
  watch: {
    events(data) {
      this.isLoading = !data;
    }
  },
  methods: {
    onEventClick(e) {
      this.$emit('itemSelected', e);
    },
    onDateClick(date) {
      console.log(date);
    },
    onViewChange(e) {
      this.dateRange = {
        start_date: e.startDate,
        end_date: e.endDate,
      };
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      this.$emit('getData', this.dateRange);
    }
  }
}
</script>

<style lang="scss" scoped>
.vuecal::v-deep {
  & .calendar-event-dot {
    font-size: 7px;
    position: absolute;
    top: 4px;
    left: 7px;
  }

  & .vuecal__cell-events-count {
    background-color: #1BC5BD !important;
  }
}
</style>